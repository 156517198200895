import { useState } from "react";

// ** Next Imports
import Image from "next/image";
import Link from "next/link";

// ** MUI Components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import MuiFormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// ** Icon Imports
import Icon from "@core/components/icon";

// ** Third Party Imports
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

// ** Hooks
import useBgColor from "@core/hooks/useBgColor";
import { useAuth } from "src/hooks/useAuth";

// ** Layout Import
import BlankLayout from "@core/layouts/BlankLayout";

// ** Constants
import { fieldLabelStyles, subtitleStyles } from "@constants/form-styles-constants";
import { inputStyles } from "@constants/data-entry-styles-constants";
import { EMAIL_REGEX, PASSWORD_REGEX } from "@constants/regex";
import { EMAIL_INVALID, EMAIL_REQUIRED, INVALID_PASSWORD, PASSWORD_LENGTH } from "@constants/validation";

// ** Demo Imports
import { useEffect } from "react";
import toast from "react-hot-toast";
import FooterIllustrationsV2 from "@views/pages/auth/FooterIllustrationsV2";
import Waving from "../../../public/images/icons/AppleEmojis/Waving.svg";
import logo from "@assets/images/logo.svg";
import { useRouter } from "next/router";

// ** Styled Components
const LoginIllustration = styled("img")(({ theme }) => ({
  zIndex: 2,
  maxHeight: 680,
  marginTop: theme.spacing(12),
  marginBottom: theme.spacing(12),
  [theme.breakpoints.down(1540)]: {
    maxHeight: 550
  },
  [theme.breakpoints.down("lg")]: {
    maxHeight: 500
  }
}));

const RightWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("md")]: {
    maxWidth: 450
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: 600
  },
  [theme.breakpoints.up("xl")]: {
    maxWidth: 750
  }
}));

const LinkStyled = styled(Link)(({ theme }) => ({
  fontSize: "0.875rem",
  textDecoration: "none",
  color: theme.palette.primary.main
}));

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    fontSize: "0.875rem",
    color: theme.palette.text.secondary
  }
}));

const schema = yup.object().shape({
  email: yup.string().required(EMAIL_REQUIRED).matches(EMAIL_REGEX, EMAIL_INVALID),
  password: yup.string().min(8, PASSWORD_LENGTH).required().matches(PASSWORD_REGEX, INVALID_PASSWORD)
});

const defaultValues = {
  password: "",
  email: ""
};

const LoginPage = () => {
  // Local States
  const [rememberMe, setRememberMe] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [isDisable, setDisable] = useState(false);

  // ** Hooks
  const auth = useAuth();
  const theme = useTheme();
  const bgColors = useBgColor();
  const hidden = useMediaQuery(theme.breakpoints.down("md"));
  const router = useRouter();
  const {
    control,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(schema)
  });

  // useEffects
  useEffect(() => {
    setDisable(!!Object.keys(errors).length);
  }, [Object.keys(errors).length]);

  // Submit Function
  const onSubmit = data => {
    const { email, password } = data;

    const smallCaseEmail = email.toLowerCase();

    auth.login({ email: smallCaseEmail, password, rememberMe }, error => {
      if (error?.response?.data?.statusCode === 429) {
        setDisable(true);
        setTimeout(() => {
          setDisable(false);
        }, 60000);

        toast.error("Too many attempts ! try again after 60 seconds", {
          duration: 3000
        });
      } else if (error?.response?.data?.statusCode === 409) {
        router.replace("/verify-email");
        toast.error("Your email is not verified.", {
          duration: 2000
        });
      } else {
        toast.error("Invalid Email or Password", {
          duration: 2000
        });
      }
    });
  };

  return (
    <Box className='content-right' sx={{ backgroundColor: "background.paper" }}>
      {!hidden ? (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            position: "relative",
            alignItems: "center",
            borderRadius: "20px",
            justifyContent: "center",
            backgroundColor: "customColors.bodyBg",
            margin: theme => theme.spacing(8, 0, 8, 8)
          }}
        >
          <LoginIllustration alt='login-illustration' src={`/images/login-illustration.svg`} />
          <FooterIllustrationsV2 />
        </Box>
      ) : null}
      <RightWrapper>
        <Box
          sx={{
            p: [6, 12],
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Box sx={{ width: "100%", maxWidth: 400 }}>
            <Image src={logo} alt='logo' width={36} height={36} />
            <Box sx={{ my: 6, marginTop: "8px" }}>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ mb: 1.5, fontWeight: 500, fontSize: " 26px", lineHeight: "36px", width: "100%" }}>
                  Welcome back to MyVigorant!{" "}
                </Typography>

                <Image src={Waving} alt='Waving' width={36} height={36} />
              </Box>

              <Typography sx={subtitleStyles}>
                Access your dental practice dashboard and take control of your success with ease.
              </Typography>
            </Box>
            <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
              <FormControl fullWidth sx={{ mb: 4 }}>
                <FormHelperText sx={fieldLabelStyles} id='auth-login-v2-password'>
                  Email
                </FormHelperText>
                <Controller
                  name='email'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <OutlinedInput
                      sx={inputStyles}
                      autoFocus
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      error={Boolean(errors.email)}
                      placeholder='john.doe@gmail.com'
                    />
                  )}
                />
                {errors.email && <FormHelperText sx={{ color: "error.main" }}>{errors.email.message}</FormHelperText>}
              </FormControl>
              <FormControl fullWidth sx={{ mb: 1.5 }}>
                <Controller
                  name='password'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <FormHelperText sx={fieldLabelStyles} id='auth-login-v2-password'>
                          Password
                        </FormHelperText>
                        <LinkStyled sx={fieldLabelStyles} href='/forgot-password'>
                          Forgot Password?
                        </LinkStyled>
                      </Box>

                      <OutlinedInput
                        sx={inputStyles}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        id='auth-login-v2-password'
                        error={Boolean(errors.password)}
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              edge='end'
                              onMouseDown={e => e.preventDefault()}
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              <Icon icon={showPassword ? "tabler:eye" : "tabler:eye-off"} fontSize={20} />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </>
                  )}
                />
                {errors.password && (
                  <FormHelperText sx={{ color: "error.main" }} id=''>
                    {errors.password.message}
                  </FormHelperText>
                )}
              </FormControl>
              <Box
                sx={{
                  mb: 1.75,
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <FormControlLabel
                  label='Remember Me'
                  control={<Checkbox checked={rememberMe} onChange={e => setRememberMe(e.target.checked)} />}
                />
              </Box>
              <Button fullWidth size='medium' type='submit' variant='contained' sx={{ mb: 4 }} disabled={isDisable}>
                Sign in
              </Button>
              <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "center" }}>
                <Typography sx={{ ...subtitleStyles, mr: 2 }}>New on our platform?</Typography>
                <Typography variant='body2'>
                  <LinkStyled href='/register' sx={{ fontSize: "1rem" }}>
                    Create an account
                  </LinkStyled>
                </Typography>
              </Box>
            </form>
          </Box>
        </Box>
      </RightWrapper>
    </Box>
  );
};
LoginPage.getLayout = page => <BlankLayout>{page}</BlankLayout>;
LoginPage.guestGuard = true;

export default LoginPage;
