export const subtitleStyles = {
  fontSize: "15px",
  lineHeight: "22px",
  fontWeight: "450",
  color: "text.secondary"
};

export const fieldLabelStyles = {
  fontWeight: 400,
  fontSize: "13px",
  lineHeight: "16px",
  margin: 0,
  marginBottom: "4px"
};
